export default [

  {
    key: 'description',
    label: 'description',
    visible: true,
    tdClass: 'tdClass',
  },

  {
    key: 'type',
    label: 'type',
    visible: true,
    tdClass: 'tdClass',
  },

  {
    key: 'ddate',
    label: 'date',
    visible: true,
    tdClass: 'tdClass',
  },
  {
    key: 'repeat',
    label: 'Repeat',
    visible: true,
    tdClass: 'tdClass',
  },

  {
    key: 'created_by',
    label: 'created by',
    visible: true,
    tdClass: 'tdClass',
  },

  {
    key: 'actions',
    label: 'actions',
    visible: true,
    tdClass: 'tdClass',
  },
]
