<template>
  <b-card>
    <div class="d-flex flex-row-reverse mt-1">
      <button
        v-if="moduleId == 17"
        type="button"
        class="btn btn-success ml-2 mb-2"
        @click="openModal(1, newForm)"
      >
        <b>+ CREATE</b>
      </button>
    </div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refHolidaysTable'].refresh()"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refHolidaysTable"
          small
          no-provider-filtering
          :items="search"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #cell(description)="data">
            <span v-if="data.item.type === 'Birthday'">
              <feather-icon
                icon="UserIcon"
                size="20"
              />
              {{ data.item.description }}
            </span>

            <span v-else>
              <feather-icon
                icon="CalendarIcon"
                size="20"
              />
              {{ data.item.description }}
            </span>
          </template>

          <template #cell(ddate)="data">
            <span v-if="data.item.rpt === 0">
              {{ data.item.ddate }}/{{ data.item.year }}
            </span>
            <span v-if="data.item.rpt === 1">
              {{ data.item.ddate }}
            </span>
          </template>

          <template #cell(created_by)="data">
            <span>
              <feather-icon
                icon="UserIcon"
                size="20"
              />
              {{ data.item.created_by }}
            </span>
            <br>
            <span>
              <feather-icon
                icon="CalendarIcon"
                size="20"
              />
              {{ data.item.created_at | myGlobalDay }}
            </span>
          </template>

          <template #cell(type)="data">
            <span
              v-if="data.item.type === 'Birthday'"
              class="text-success"
            >
              {{ data.item.type }}
            </span>

            <span
              v-if="data.item.type === 'Other'"
              class="text-warning"
            >
              {{ data.item.type }}
            </span>

            <span
              v-if="data.item.type === 'Day Calendar'"
              class="text-primary"
            >
              {{ data.item.type }}
            </span>
          </template>

          <template #cell(repeat)="data">
            <span
              v-if="data.item.rpt == 1"
              class="text-primary"
            > Always </span>
            <span
              v-else
              class="text-warning"
            > One time </span>
          </template>

          <template
            v-if="moduleId == 17"
            #cell(actions)="data"
          >
            <feather-icon
              v-if="
                data.item.type === 'Day Calendar' || data.item.type === 'Other'
              "
              class="text-warning mr-2 cursor-pointer"
              icon="EditIcon"
              size="20"
              @click="openModal(2, data.item)"
            />

            <feather-icon
              v-if="
                data.item.type === 'Day Calendar' || data.item.type === 'Other'
              "
              class="text-danger cursor-pointer"
              icon="Trash2Icon"
              size="20"
              @click="deleteHolidays(data.item)"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>

    <modal-add-holiday
      v-if="modal"
      :operation="operation"
      :holiday="holidaySelected"
      @hidden="modal = false"
      @closeModal="modal = false"
      @refreshTable="refreshTable"
    />
  </b-card>
</template>
<script>
import Fields from '@/views/assistance/views/dashboard/components/holidays/views/data/fields.holidays.data';
import filtersData from '@/views/assistance/views/dashboard/components/holidays/views/data/filters.holidays.data';
import Service from '@/views/assistance/views/dashboard/components/holidays/services/holidays.service';
import ModalAddHoliday from '@/views/assistance/views/dashboard/components/holidays/views/components/ModalAddHoliday.vue';
import moment from 'moment';

export default {
  components: {
    ModalAddHoliday,
  },
  data() {
    return {
      operation: 1,
      modal: false,
      holidaySelected: {},
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      sortBy: 'name',
      sortDesc: false,
      fields: Fields,
      startPage: null,
      endPage: '',
      nextPage: '',
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Type description or Employee name ...',
        model: '',
      },
      filter: filtersData,
      transactionId: null,
      newForm: {
        description: '',
        day: null,
        month: null,
        created_by: '',
        rpt: '1',
        type: 'Day Calendar',
      },
    };
  },

  computed: {
    visibleFields() {
      return this.fields;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    filterMonth() {
      return this.filter[3].model;
    },
  },
  watch: {
    filterMonth() {
      this.getDayByMonth();
    },
  },
  async created() {
    await this.listAllUsers();
  },
  mounted() {
    this.getDayByMonth();
  },

  methods: {
    async deleteHolidays(obj) {
      const resultState = await this.showConfirmSwal();

      if (resultState) {
        const resul = await Service.deleteHolidays(obj);

        if (resul.status === 200) {
          this.showSuccessSwal();
          this.refreshTable();
        }
      }
    },

    refreshTable() {
      this.$refs.refHolidaysTable.refresh();
    },

    openModal(value, holiday) {
      this.operation = value;
      this.holidaySelected = holiday;
      this.modal = true;
    },

    async search(ctx) {
      try {
        const obj = {
          name_text: this.filterPrincipal.model,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
          sort_by: ctx.sortBy,
          sort: ctx.sortDesc === true ? 'DESC' : 'ASC',
          created_by: this.filter[0].model,
          is_repeat: this.filter[1].model,
          day: this.filter[2].model,
          month: this.filter[3].model,
          type: this.filter[4].model,
        };

        const res = await Service.getHolidays(obj);
        if (res.status === 200) {
          this.clients = res.data.data;
          this.startPage = res.data.from;
          this.paginate.currentPage = res.data.current_page;
          this.paginate.perPage = res.data.per_page;
          this.nextPage = this.startPage + 1;
          this.endPage = res.data.last_page;
          this.totalRows = res.data.total;
          this.toPage = res.data.to;
          return res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
      return [];
    },
    async listAllUsers() {
      try {
        const res = await Service.listAllUsers();
        if (res.status === 200) {
          this.filter[0].options = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    getDayByMonth() {
      const currentYear = moment().format('YYYY');
      const month = parseInt(this.filter[3].model, 10);
      const lastDay = month === 2
        ? 29
        : moment(`${currentYear}-${month}`, 'YYYY-MM').daysInMonth();
      this.filter[2].options = [];
      this.filter[2].options.push({ label: 'All', value: null });
      for (let index = 1; index <= lastDay; index += 1) {
        this.filter[2].options.push({ label: index, value: index });
      }
    },
  },
};
</script>

<style>
.tdClass {
  font-size: 14px;
  padding: 7px 10px !important;
}
</style>
