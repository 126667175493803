<template>
  <div>
    <b-modal
      ref="modal-add-test-holiday"
      no-close-on-backdrop
      size="lg"
      :title="title + ' HOLIDAY'"
      @hidden="$emit('hidden')"
    >
      <div class="m-2">
        <ValidationObserver ref="observer">
          <div class="form-row">
            <div class="form-group col-md-12">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|max:254"
                name="description"
              >
                <b-form-group label="Description">
                  <b-form-input
                    v-model="form.description"
                    type="text"
                    class="form-control"
                    :class="errors[0]"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small
                    v-if="errors.length > 0"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="form-group col-md-3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="month"
              >
                <b-form-group label="Month">
                  <b-form-select
                    v-model="form.month"
                    :options="months"
                    :class="errors[0]"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small
                    v-if="errors.length > 0"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div
              v-if="form.month != null"
              class="form-group col-md-3"
            >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="day"
              >
                <b-form-group label="Day">
                  <b-form-select
                    v-model="form.day"
                    :options="days"
                    :class="errors[0]"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small
                    v-if="errors.length > 0"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="form-group col-md-3">
              <b-form-group label="Is repeated holiday?">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.rpt"
                  name="checkbox-1"
                  value="1"
                  unchecked-value="0"
                >
                  Yes
                </b-form-checkbox>
              </b-form-group>
            </div>

            <div class="form-group col-md-3">
              <b-form-group label="Type">
                <b-form-select
                  v-model="form.type"
                  :options="typeOptions"
                />
              </b-form-group>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <template #modal-footer>
        <div>
          <b-button
            class=""
            @click="cancel()"
          >
            CANCEL
          </b-button>
        </div>
        <div>
          <b-button
            variant="primary"
            @click="createHoliday()"
          ><feather-icon
             icon="SaveIcon"
             class="mr-1"
           />
            {{ textButton }}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Service from '@/views/assistance/views/dashboard/components/holidays/services/holidays.service';
import moment from 'moment';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    operation: {
      type: Number,
      required: true,
    },
    holiday: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      days: [],
      months: [
        { value: null, text: 'Select a month' },
        { value: '01', text: 'January' },
        { value: '02', text: 'February' },
        { value: '03', text: 'March' },
        { value: '04', text: 'April' },
        { value: '05', text: 'May' },
        { value: '06', text: 'June' },
        { value: '07', text: 'July' },
        { value: '08', text: 'August' },
        { value: '09', text: 'September' },
        { value: '10', text: 'October' },
        { value: '11', text: 'November' },
        { value: '12', text: 'December' },
      ],
      typeOptions: [
        { value: 'Day Calendar', text: 'Day Calendar' },
        { value: 'Other', text: 'Other' },
      ],
      form: {},
      title: '',
      textButton: 'SAVE',
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    filterMonth() {
      return this.form.month;
    },
  },

  watch: {
    filterMonth() {
      this.getDayByMonth();
    },
  },

  mounted() {
    this.toggleModal('modal-add-test-holiday');

    if (this.operation === 1) {
      this.title = 'ADD';
      this.form = { ...this.holiday };
    }

    if (this.operation === 2) {
      this.title = 'UPDATE';
      this.form = { ...this.holiday };
    }
  },

  methods: {
    getDayByMonth() {
      const currentYear = moment().format('YYYY');
      const month = parseInt(this.form.month, 10);
      const lastDay = month === 2
        ? 29
        : moment(`${currentYear}-${month}`, 'YYYY-MM').daysInMonth();
      this.days = [];
      this.days.push({ value: null, text: 'All' });
      for (let index = 1; index <= lastDay; index += 1) {
        const day = (index < 10) ? `0${index}` : String(index);
        this.days.push({ value: day, text: day });
      }
    },

    cancel() {
      this.$emit('closeModal');
    },

    async createHoliday() {
      try {
        const result = await this.$refs.observer.validate();
        if (!result) {
          return;
        }
        const resultState = await this.showConfirmSwal();

        if (resultState.isConfirmed) {
          this.form.operation = this.operation;
          this.form.created_by = this.currentUser.user_id;

          if (this.form.type === 'Day Calendar') {
            this.form.type = 1;
          } else {
            this.form.type = 2;
          }

          this.addPreloader();

          const resul = await Service.addHolidays(this.form);

          if (resul.status === 200) {
            this.showSuccessSwal();
            this.removePreloader();
            this.$emit('closeModal');
            this.$emit('refreshTable');
          }
          if (resul.status === 207) {
            this.showWarningSwal(resul.data);
            this.removePreloader();
          }
        }
      } catch (error) {
        this.showErrorSwal();
        console.log(error);
        this.removePreloader();
      }
    },
  },
};
</script>
