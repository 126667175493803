/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class HolidayService {
  async getHolidays(body) {
    const data = await amgApi.post('/attendance/get-holidays-attendance', body);
    return data;
  }

  async addHolidays(form) {
    const data = await amgApi.post('/attendance/add-holidays-attendance', form);
    return data;
  }

  async deleteHolidays(form) {
    const data = await amgApi.post('/attendance/delete-holidays-attendance', form);
    return data;
  }

  async listAllUsers() {
    const data = await amgApi.post('/attendance/holidays-attendance-users');
    return data;
  }
}

export default new HolidayService();
